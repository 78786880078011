<template>
<!-- 商品品牌 -->
  <div class="whiteBg">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="table_cont">
      <el-table
        :header-cell-style="{background:'#EBF2FE'}"
        :data="combrandList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          width="200">
        </el-table-column>
        <el-table-column
          label="品牌名称"
          prop="gbname">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" size="small"  style="color:#3C84EF;" @click="handleEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!-- 新增编辑弹窗 -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="unitfrom" :rules="formRules" ref="unitFrom">
        <el-form-item label="品牌名称" prop="gbname">
          <el-input v-model="unitfrom.gbname"  placeholder="请输入品牌名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { goodbrandGet, goodbrandAdd, goodbrandUpdate, goodbrandDelete } from '@/api/commodity'
export default {
  name: 'commodityBrand',
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      combrandList: [],
      fromShow: false,
      unitfrom: {
        gbname: ''
      },
      formRules: {
        gbname: [
          { required: true, trigger: 'blur', message: '品牌名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      dialogName: '',
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size
      }
      goodbrandGet(params).then(res => {
        if (res.status === 1) {
          this.combrandList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.combrandList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    // 新增
    handleAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.unitfrom = {}
    },
    // 编辑
    handleEdit (row) {
      this.unitfrom = { ...row }
      this.fromShow = true
      this.dialogName = '编辑'
    },
    // [新增-编辑]提交
    FromConfirm () {
      this.$refs.unitFrom.validate((valid) => {
        if (!valid) return
        if (this.dialogName === '新增') {
          goodbrandAdd({gbname: this.unitfrom.gbname}).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            }
          })
        } else if (this.dialogName === '编辑') {
          const params = {
            gbid: this.unitfrom.gbid,
            gbname: this.unitfrom.gbname
          }
          goodbrandUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            }
          })
        }
      })
    },
    FromCancel () {
      this.$refs.unitFrom.resetFields()
      this.fromShow = false
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        goodbrandDelete({ gbid: row.gbid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
  }
}
</script>

<style scoped>

</style>
